footer,
#pre-footer {
  width: 100%;
}

footer {
  background: rgb(37, 37, 37);
  text-align: center;
  padding-bottom: 30px;
}

footer p {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

footer a {
  margin-top: 50px;
  margin-bottom: 60px;
  font-weight: initial;
  font-size: 13px;
}

footer a:link,
footer a:visited {
  color: white;
}

footer a:hover {
  color: #70e2d9;
}

#pre-footer {
  height: 40px;
  background: rgb(61, 61, 61);
}

.italic {
  font-style: italic;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

@media (min-width: 568px) {
  footer {
    padding-bottom: 30px;
  }

  footer a {
    margin-bottom: 80px;
  }
}

@media (min-width: 667px) {
  #pre-footer {
    height: 60px;
  }

  footer {
    padding-bottom: 50px;
  }

  footer a {
    margin-top: 85px;
    margin-bottom: 35px;
  }
}

@media (min-width: 736px) {
  #pre-footer {
    height: 64px;
  }

  footer a {
    margin-top: 65px;
    margin-bottom: 35px;
  }
}

@media (min-width: 768px) {
  #pre-footer {
    height: 64px;
  }

  footer a {
    margin-top: 55px;
    margin-bottom: 25px;
  }
}

@media (min-height: 812px) and (max-width: 375px) {
  footer a {
    margin-bottom: 55px;
  }
}
