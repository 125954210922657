@import url('https://fonts.googleapis.com/css?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css?family=Be+Vietnam&display=swap');

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3,
h5,
li a,
footer a {
  font-family: 'Work Sans', sans-serif;
}

p,
a,
label,
blockquote {
  font-family: 'EB Garamond', serif;
}

.blockquote-quote {
  font-family: 'Be Vietnam', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
