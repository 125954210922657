section {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-bottom: 20px;
  color: rgba(26, 26, 26, 0.7);
  min-height: 96vh;
}

p {
  font-size: 18px;
  line-height: 1.6em;
  letter-spacing: 0px;
  font-weight: 400;
  font-style: normal;
  margin: 9px 0;
}

button {
  height: 40px;
  border-radius: 5px;
  background: #3d9991;
  border: 2px solid #3d9991;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

button:hover {
  cursor: pointer;
  background: white;
  color: #3d9991;
}

a {
  font-size: 18px;
}

a:link,
a:visited {
  color: #3d9991;
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  color: #70e2d9;
}

#introduction h1 {
  font-size: 22px;
  margin-top: 14px;
  margin-bottom: 24px;
}

#introduction-apply-button {
  margin: 20px 0 20px 0;
}

#teaching-studio p,
#bookings p {
  margin: 20px 0 26px 0;
}

#bookings {
  padding-bottom: 0;
}

#apply-information-text {
  margin-bottom: 0;
}

#apply-information-sub-text {
  margin-top: 0;
  margin-bottom: 20px;
}

.container {
  padding: 40px 20px;
}

.main-image {
  width: 100%;
  padding: 10px 0;
}

.main-sub-section {
  padding-bottom: 35px;
}

.main-sub-section h2 {
  margin-bottom: 0;
}

.main-sub-section p {
  text-align: center;
}

.centered-text {
  text-align: center;
}

.demo-login-button {
  background: none;
  color: #3d9991;
  border: none;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

.demo-login-button:hover {
  color: #70e2d9;
}

.homepage-image {
	max-width: 100vw;
	max-height: 100vh;
	width: 100%;
	height: auto;
	padding: 30px 10px 10px 10px;
}

.social-link {
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 10px;
	font-size: 24px;
	margin-top: 10px;
}

.social-icon {
	max-width: 100%;
	width: 30px;
	height: auto;
	margin-right: 6px;
}

@media (min-width: 667px) {
  section {
    padding-bottom: 12px;
  }

  section p {
    width: 59vw;
  }

  #introduction-apply-button {
    margin-top: 25px;
  }

  .container {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .flex-responsive-landscape-main {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
  }

  .main-sub-section p {
    width: 100%;
  }

  .main-sub-section {
    width: 45%;
  }

	.social-link {
		margin-top: 20px;
	}
}

@media (min-width: 768px) {
  #introduction h1 {
    margin-top: 73px;
  }

	.homepage-image {
		width: 80%;
		max-width: 59vw;
		padding-left: 0;
		padding-right: 0;
	}
}

@media (min-width: 1200px) {
  section {
    padding-left: 230px;
    padding-right: 230px;
  }

  section p {
    width: 630px;
  }

  #introduction h1 {
    margin-top: 50px;
  }

	.homepage-image {
		width: 80%;
		max-width: 630px;
	}
}
