blockquote {
  font-size: 20px;
  line-height: 1.65em;
  letter-spacing: 0px;
  font-weight: 400;
  color: rgba(26, 26, 26, 0.9);
  text-align: center;
  margin-top: -29px;
  margin-bottom: 43px;
}

#what-we-do {
  padding-top: 50px;
}

#what-we-do h1 {
  width: 100%;
  margin-top: 18px;
  margin-bottom: 20px;
}

#what-we-do p {
  width: 100%;
}

#what-we-do .breakpoint__small-down {
  width: 100%;
}

.blockquote-quote {
  font-size: 76px;
  opacity: 0.3;
  margin-top: 55px;
}

@media (min-width: 667px) {
  blockquote {
    margin-top: -33px;
  }

  #what-we-do {
    flex-direction: row;
    justify-content: space-between;
    align-items: self-start;
    padding-top: 14px;
  }

  #studio-information {
    padding-left: 30px;
    padding-right: 30px;
  }

  #studio-images {
    width: 157px;
  }

  .page-menu-desktop {
    width: 191px;
    padding-right: 20px;
  }

  .page-menu-desktop h2 {
    margin-top: 18px;
    font-family: 'EB Garamond', serif;
    color: #00746b;
    font-size: 22px;
    margin-bottom: 8px;
  }

  .page-dropdown-list-desktop li {
    padding-bottom: 9px;
  }

  .blockquote-quote {
    margin-top: -4px;
  }
}

@media (min-width: 736px) {
  blockquote {
    width: 127px;
    margin-bottom: 28px;
  }

  #studio-information {
    padding-right: 64px;
  }

  #studio-images {
    width: 191px;
  }

  #studio-images img {
    width: 191px;
  }
}

@media (min-width: 768px) {
  #what-we-do {
    margin-top: 60px;
    padding-bottom: 90px;
  }

  #studio-information h1 {
    padding-right: 40px;
  }

  #studio-images img {
    width: 207px;
  }
}

@media (min-width: 812px) and (max-height: 375px) {
  #studio-information {
    margin-left: 65px;
  }
}

@media (min-width: 1000px) {
  blockquote {
    width: 260px;
  }

  #studio-information {
    padding: 0;
    width: 333px;
  }

  #studio-images,
  #studio-images img {
    width: 333px;
  }

  #what-we-do {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 1200px) {
  #what-we-do {
    margin-left: 200px;
    margin-right: 200px;
  }
}
