.page-dropdown {
  align-self: flex-end;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.page-dropdown-button:hover {
  cursor: pointer;
  color: lightgray;
}
