#suzuki-method {
  padding-top: 54px;
}

#suzuki-method .breakpoint__small-down {
  width: 100%;
}

#suzuki-method h1,
#suzuki-method p {
  width: 100%;
}

#suzuki-method h1 {
  margin-bottom: 3px;
}

#suzuki-method a p:first-child {
  margin-top: 0;
  margin-bottom: 10px;
}

#suzuki-method a {
  width: 100%;
}

#suzuki-method blockquote {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

#suzuki-image {
  height: 49vh;
  background: url('../../../img/Suzuki.jpg');
  background-position: center;
  background-size: cover;
  margin-top: 16px;
  margin-bottom: 10px;
}

#new-students-information {
  display: flex;
  flex-direction: column;
}

#new-students-information blockquote,
#new-students-information .blockquote-quote {
  align-self: center;
}

.bold {
  font-weight: bold;
}

.image-info {
  font-size: 14px;
  margin-bottom: 10px;
}

.sub-title {
  margin-top: 15px;
}

@media (max-width: 666px) and (orientation: landscape) {
  #suzuki-image {
    height: 460px;
  }
}

@media (min-width: 667px) {
  #suzuki-image {
    height: 180px;
    width: 250px;
  }
}

@media (min-width: 736px) {
  #suzuki-method blockquote {
    width: initial;
  }
}

@media (min-width: 768px) {
  #suzuki-image {
    height: 207px;
    width: 277px;
  }
}

@media (min-width: 1000px) {
  #suzuki-image {
    height: 250px;
    width: 350px;
  }
}
