#contact {
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 45px;
}

#contact h1 {
  margin-top: 20px;
}

#contact-info p {
  margin-bottom: 20px;
}

#email-form {
  margin-top: 15px;
  padding-left: 10px;
}

#email-form-button {
  align-self: flex-start;
  width: 136px;
  font-size: 14px;
}

.black-button {
  background: black;
  border: 2px solid black;
  border-radius: 0;
}

.black-button:hover {
  color: black;
  opacity: 0.8;
}

.contact-icon {
  width: 24px;
  margin-right: 10px;
}

.email-icon {
  width: 25px;
}

.flex-center-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (min-width: 667px) {
  #email-form {
    margin-top: 10px;
    padding-left: 40px;
  }
}

@media (min-width: 736px) {
  #email-form {
    padding-left: 60px;
  }
}

@media (min-width: 768px) {
  #contact {
    padding-top: 90px;
  }

  #email-form {
    padding-left: 80px;
  }
}

@media (min-width: 812px) {
  #email-form {
    padding-left: 90px;
  }
}

@media (min-width: 1000px) {
  #email-form {
    padding-left: 140px;
  }
}

@media (min-width: 1200px) {
  #email-form {
    padding-left: 156px;
  }
}
